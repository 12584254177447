<template>
  <!-- Campaigns-List-table-area  -->
  <div class="leads-table-area campaigns-list-area">
    <!-- <button data-toggle="modal" data-target="#addCampaignListModal" class="rem-btn add-campaign-list-btn"><img class="img-fluid" src="../../assets/img/23. Close.svg" alt=""> Add Campaign List</button> -->
    <router-link
      :to="{ name: 'campaignListGenerator' }"
      class="rem-btn add-campaign-list-btn"
      ><img class="img-fluid" src="../../assets/img/23. Close.svg" alt="" /> Add
      Campaign List</router-link
    >
    <table id="campaigns_table" class="display">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Recipients</th>
          <th>Status</th>
          <th>Created</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>

    <!-- add Campaign List Modal -->
    <div
      class="modal fade"
      id="addCampaignListModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addCampaignListModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addTeamModalLabel">
              Add Campaign List
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addCampaignList">
            <div class="modal-body">
              <div class="add-member">
                <div class="form-group">
                  <label for="acl_name">Name</label>
                  <input
                    type="text"
                    v-model="cl_.name"
                    class="form-control"
                    id="acl_name"
                    placeholder="Enter campaign list name"
                  />
                  <div
                    class="validate-error-message"
                    v-if="$vd.cl_.name.$hasError === true"
                  >
                    {{ $vd.cl_.$errors.name[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Users list</label>
                  <select class="form-control" v-model="cl_.user_cate">
                    <option value="all">All Users</option>
                    <option value="customers">Only Our Customers</option>
                    <option value="employees">Only Our Employees</option>
                    <option value="dealers">All Dealers</option>
                  </select>
                  <div
                    class="validate-error-message"
                    v-if="$vd.cl_.user_cate.$hasError === true"
                  >
                    {{ $vd.cl_.$errors.user_cate[0] }}
                  </div>
                </div>
                <div
                  class="additional-filters row"
                  v-show="
                    cl_.user_cate == 'all' || cl_.user_cate == 'customers'
                  "
                >
                  <h5 class="mt-2 mb-2 col-12">Additional Filters:</h5>
                  <div class="form-group col-12">
                    <label class="con-company send-tab">
                      Upsheet from given source
                      <input
                        type="radio"
                        name="ad_filter"
                        v-model="cl_.by"
                        value="by_source"
                        v-on:change="selectedFilter($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group col-12">
                    <label class="con-company sche-tab">
                      Purchased or Interested in specific vehicle
                      <input
                        type="radio"
                        name="ad_filter"
                        v-model="cl_.by"
                        value="by_specific_vehicle"
                        v-on:change="selectedFilter($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group col-12">
                    <label class="con-company sche-tab">
                      Located near a specific area
                      <input
                        type="radio"
                        name="ad_filter"
                        v-model="cl_.by"
                        value="by_area"
                        v-on:change="selectedFilter($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <!-- <div class="form-group col-12">
                                    <label class="con-company zoon-tab">
                                        Specific users
                                        <input type="radio" name="ad_filter" v-on:change="selectedFilter($event)" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div> -->
                </div>
              </div>
              <div
                class="add-member"
                v-show="cl_.user_cate == 'all' || cl_.user_cate == 'customers'"
              >
                <div v-if="cl_.by == 'by_specific_vehicle'">
                  <div class="form-group">
                    <label for="acl_model">Vehicle model</label>
                    <input
                      type="text"
                      v-model="cl_.model"
                      class="form-control"
                      id="acl_model"
                      placeholder="Enter model"
                    />
                  </div>
                  <div class="form-group">
                    <label for="vtype">Vehicle Sale Type</label>
                    <select class="form-control" id="vtype" v-model="cl_.vtype">
                      <option value="">All</option>
                      <option value="6">Sold</option>
                      <option value="7">Lost</option>
                    </select>
                  </div>
                  <div class="additional-filters row">
                    <h5 class="mt-2 mb-2 col-12">Time period:</h5>
                    <div class="form-group col-12">
                      <label class="con-company send-tab">
                        All Time
                        <input
                          type="radio"
                          v-model="cl_.time_period"
                          value="all"
                          v-on:change="selectedFilter($event)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group col-12">
                      <label class="con-company send-tab">
                        User's who bought or leased vehicle three years ago
                        <input
                          type="radio"
                          v-model="cl_.time_period"
                          value="threeYago"
                          v-on:change="selectedFilter($event)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group col-12">
                      <label class="con-company sche-tab">
                        Specific time period
                        <input
                          type="radio"
                          v-model="cl_.time_period"
                          value="specific_time_period"
                          v-on:change="selectedFilter($event)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div
                      class="form-group col-6"
                      v-if="cl_.time_period == 'specific_time_period'"
                    >
                      <label>Start date</label>
                      <input
                        type="date"
                        v-model="cl_.sdate"
                        class="form-control"
                        placeholder="Start date"
                      />
                    </div>
                    <div
                      class="form-group col-6"
                      v-if="cl_.time_period == 'specific_time_period'"
                    >
                      <label>End date</label>
                      <input
                        type="date"
                        v-model="cl_.edate"
                        class="form-control"
                        placeholder="End date"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group" v-if="cl_.by == 'by_source'">
                  <label for="source">Select Source</label>
                  <select class="form-control" id="source" v-model="cl_.source">
                    <option value="all">Select source</option>
                    <option
                      v-for="source in LSources"
                      :value="source.ls_id"
                      :key="source.ls_id"
                    >
                      {{ source.ls_name }}
                    </option>
                  </select>
                  <div
                    class="validate-error-message"
                    v-if="$vd.cl_.source.$hasError === true"
                  >
                    {{ $vd.cl_.$errors.source[0] }}
                  </div>
                </div>
                <div class="form-group" v-if="cl_.by == 'by_area'">
                  <label for="acl_zip">Zip code</label>
                  <input
                    type="text"
                    v-model="cl_.zip"
                    class="form-control"
                    id="acl_zip"
                    placeholder="Enter zip code"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Save campaign list
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add Campaign List Modal -->
  </div>
  <!-- Campaigns-List-table-area  -->
</template>

<script>
import axios from "axios";
import $ from "jquery";
import VueDaval from "vue-daval";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
//import Select2 from 'v-select2-component';

//import DateRangePicker from 'vue2-daterange-picker'
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

//import MultiSelect from '../helpers/MultiSelect'

export default {
  name: "CampaignsList",
  components: {},
  mixins: [VueDaval],
  data() {
    return {
      cl_: { name: "", user_cate: "all", source: "", by: "", vtype: "" },
      LSources: [],
    };
  },
  vdRules: {
    cl_: {
      name: { required: true },
      user_cate: { required: true },
    },
  },
  created() {
    this.getLeadSource();
  },
  methods: {
    addCampaignList() {
      this.$vd.cl_.$validate().then(() => {
        //console.log(this.cl_)
        axios
          .post("add_campaign_list", this.cl_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              $("#addCampaignListModal").modal("hide");
              $("#campaigns_table").dataTable().fnDraw();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    selectedFilter(e) {
      if (e.target.checked) {
        $(e.target.parentNode).addClass("currnt");
      } else {
        $(e.target.parentNode).removeClass("currnt");
      }
    },
    changeCampaignListStatus(id, status) {
      axios
        .post(
          "change_campaign_list_status",
          { id: id, status: status },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (status == 1) {
              $("#statusChange" + id).removeClass("assin");
              $("#statusChange" + id).addClass("btn-clr-danger");
              $("#statusChange" + id).text("InActive");
              $("#statusChange" + id).data("status", 0);
            } else {
              $("#statusChange" + id).addClass("assin");
              $("#statusChange" + id).removeClass("btn-clr-danger");
              $("#statusChange" + id).text("Active");
              $("#statusChange" + id).data("status", 1);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadSource() {
      axios
        .get("lead_sources")
        .then((res) => {
          if (res.data.success) {
            this.LSources = res.data.success;
            //$emit('enlarge-text', 0.1)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    $("#campaigns_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: axios.defaults.baseURL + "dt_campaigns_list",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        drawCallback: function () {
          //alert( 'Table redrawn '+new Date() );
        },
        // columnDefs: [
        //     { className: 'dt-body-right' },
        //     {
        //         "targets": [7,8],
        //         'orderable': false,
        //     },
        // ]
        //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
      });
    let vm = this;
    $("tbody").on("click", ".clStatus", function () {
      var id = $(this).data("id");
      var status = $(this).data("status");
      vm.changeCampaignListStatus(id, status);
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.add-campaign-list-btn {
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 1;
}
.campaigns-list-area {
  position: relative;
}
</style>